import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { MainCard } from '../../components/MainCard';
import { Flex, H, PrimaryButton } from '../../components';
import { Row, Col, Spin } from 'antd';
import { DownloadIcon } from '../../assets';
import styled from '@emotion/styled';
import ReportTable from './ReportTable';
import VenuesSelector from '../item/VenuesSelector';
import { useGeneratePdfReport, useGetReportData } from '../../api/reportHooks';
import { convertToADateDataObject, getLastNDays } from '../../utils/reportUtil';
import { DateFrequencyEnum } from '../../types/report.types';
import DateFrequencySelector from '../../components/datePicker/DateRangePickerComp';
import { Text } from '../../components';
import theme from '../../theme';

const Card = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media screen and (max-width: 760px) {
    display: inherit;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  @media (max-width: 760px) {
    width: 100%;
  }
  @media (min-width: 760px) {
    margin-left: 16px;
  }
`;

const Reports: FC = () => {
  const [selectedVenueId, setSelectedVenueId] = useState<string>();
  const [selectedFrequency, setSelectedFrequency] = useState<DateFrequencyEnum>(
    DateFrequencyEnum.LAST30DAYS,
  );
  const [selectedVenueName, setSelectedVenueName] = useState<string>();
  const [venueKeys, setVenueKeys] = useState<number[]>([]);
  const [lastNDaysFormatted, setLastNDaysFormatted] = useState<string[]>();
  const initialDataSource = [
    {
      key: 1,
      venue: 'Venue',
      date_1: selectedVenueName,
    },
    {
      key: 2,
      venue: 'Date',
      ...lastNDaysFormatted,
    },
  ];
  const [dataSource, setDataSource] = useState(initialDataSource);

  useEffect(() => {
    if (selectedFrequency) {
      const lastndays = getLastNDays(selectedFrequency);
      if (lastndays) {
        const formattedLastNDays = convertToADateDataObject(lastndays);
        setLastNDaysFormatted(formattedLastNDays);
      }
    }
  }, [selectedFrequency]);

  const {
    mutate: getReportData,
    data: reportData,
    isSuccess: isSuccessGetReportData,
    isLoading: isLoadingGetReportData,
  } = useGetReportData();

  const { mutate: generatePdf, isLoading: isLoadingPdfGeneration } =
    useGeneratePdfReport();

  const setReportDataToDataSource = () => {
    const areas = reportData?.areas;
    let key = 3;

    const fixedRows = reportData?.fixedRows;
    if (fixedRows) {
      const taskCompletedRow = Object.assign(
        {},
        ...fixedRows.completionRow.map((value, index) => ({
          [`date_${index + 1}`]: value,
        })),
      );
      initialDataSource.push({
        key: key,
        venue: 'Task Completion',
        ...taskCompletedRow,
      });
      key++;

      const taskCompletionTimeRow = Object.assign(
        {},
        ...fixedRows.completedTimeRow.map((value, index) => ({
          [`date_${index + 1}`]: value,
        })),
      );
      initialDataSource.push({
        key: key,
        venue: 'Completed Time',
        ...taskCompletionTimeRow,
      });
      key++;

      const scheduleRow = Object.assign(
        {},
        ...fixedRows.scheduleRow.slice(0, 30).map((value, index) => ({
          [`date_${index + 1}`]: value,
        })),
      );

      initialDataSource.push({
        key: key,
        venue: 'Schedule',
        ...scheduleRow,
      });
      key++;
    }

    if (areas) {
      areas.forEach((area) => {
        initialDataSource.push({
          key: key,
          venue: area.areaName,
        });
        const currentVenueKeys = venueKeys;
        if (!currentVenueKeys.includes(key)) {
          currentVenueKeys.push(key);
        }
        setVenueKeys(currentVenueKeys);
        key++;
        const { tasks } = area;
        tasks.forEach((task) => {
          initialDataSource.push({
            key: key,
            venue: task.name,
            ...task.dailyStatus,
          });
          key++;
        });
      });
    }

    setDataSource(initialDataSource);
  };

  useEffect(() => {
    if (reportData && isSuccessGetReportData) {
      setReportDataToDataSource();
    }
  }, [reportData]);

  useEffect(() => {
    if (selectedVenueId && selectedFrequency) {
      getReportData({
        venueId: selectedVenueId,
        dateFrequency: selectedFrequency,
      });
    }
  }, [selectedVenueId, selectedFrequency]);

  return (
    <Spin spinning={isLoadingPdfGeneration || isLoadingGetReportData}>
      <MainCard style={{ paddingBottom: '20px' }}>
        <Flex m="0px 24px 16px">
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <H type="title3Bold">Reports</H>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card>
                <Flex display="flex" flexWrap="wrap">
                  <DateFrequencySelector
                    dateFrequency={selectedFrequency}
                    setDateFrequency={setSelectedFrequency}
                  />
                  <VenuesSelector
                    width={200}
                    onChange={(venueId: string, venue?: string) => {
                      setSelectedVenueId(venueId);
                      setSelectedVenueName(venue);
                    }}
                  />
                  <StyledPrimaryButton
                    onClick={() => {
                      if (selectedVenueId && selectedFrequency) {
                        generatePdf({
                          venueId: selectedVenueId,
                          dateFrequency: selectedFrequency,
                        });
                      }
                    }}
                    size="middle"
                    disabled={isLoadingPdfGeneration}
                    icon={<DownloadIcon />}>
                    Download
                  </StyledPrimaryButton>
                </Flex>
              </Card>
            </Col>
          </Row>
        </Flex>

        <Flex pb="5px">
          <ReportTable
            venue={selectedVenueId}
            dataSource={dataSource}
            schedule={selectedFrequency}
            venueKeys={venueKeys}
          />
        </Flex>
      </MainCard>
      <Flex
        height={48}
        background={theme.white}
        position="fixed"
        left={0}
        bottom={0}
        width="100%"
        display="flex"
        alignItems="center"
        pl="32px"
        borderTop={`1px solid ${theme.gray400}`}
        z-index={1000}>
        <Text type="footnote-bold">D</Text>
        <Text type="footnote-regular"> - Daily</Text>,
        <Text type="footnote-bold">W</Text>
        <Text type="footnote-regular"> - Weekl</Text>y,
        <Text type="footnote-bold">Y</Text>
        <Text type="footnote-regular"> - Yearl</Text>y
      </Flex>
    </Spin>
  );
};

export default Reports;
