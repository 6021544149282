import styled from '@emotion/styled';
import type { TableColumnsType } from 'antd';
import { Col, Dropdown, Menu, Popover, Row, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';

import {
  useCreateProduct,
  useGetAllProducts,
  useToggleProductStatus,
  useUpdateProduct,
} from '../../api/cleaningProductsHooks';
import { MenuThreeDots, Sort } from '../../assets';
import { Flex, H, Image, Text } from '../../components';
import { MainCard } from '../../components/MainCard';
import ButtonDropdown from '../../components/dropdown/ButtonDropdown';
import { SearchInput } from '../../components/inputs/SearchInput';
import TableComponent from '../../components/table/TableComponent';
import { getCategoryTag } from '../../components/tags/CategoryTag';
import { useAuth } from '../../providers/auth-context';
import theme from '../../theme';
import {
  CleaningProductFormI,
  CleaningProductTypeEnum,
  CleaningProductsI,
  CleaningProductsStatusEnum,
  ManageByEnum,
} from '../../types/cleaningProducts.types';
import { UserRoleIdEnum } from '../../types/user.types';
import { useWindowSize } from '../../utils/screenSize';
import { tableSorter } from '../../utils/tableSorter';
import CategorySelector from './CategorySelector';
import EquipmentModal from './EquipmentModal';
import { getManageByTag } from './ManageBy';
import { getStatusTag } from './StatusTag';
import { DeleteModal } from '../../components/modals/DeleteModal';

const PreviewImage = styled(Image)`
  .ant-image .ant-image-img {
    border-radius: 4px;
  }
`;

const MenuItem = styled(Menu.Item)`
  border-top: 0.5px solid ${theme.gray300};
  .ant-dropdown-menu-title-content {
    padding: 7px 54px 7px 4px;
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Card = styled(Flex)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 760px) {
    display: inherit;
    margin-right: 0px !important;
    padding: 0px 24px 12px 24px;
  }
`;

const ButtonFlex = styled(Flex)`
  @media screen and (max-width: 760px) {
    margin-top: 8px;
  }
`;
const Preview = (src: string) => {
  return <Image src={src} width={'184px'} />;
};
export const getColumns = (
  roleId: number,
  productStatusChange: (id: string, status: CleaningProductsStatusEnum) => void,
  handleEdit: (id: string) => void,
) => {
  const columns: TableColumnsType<CleaningProductsI> = [
    {
      title: (
        <Flex display="flex" alignItems="center">
          Code <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'code',
      width: 150,
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.code, b.code),
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Cleaning Product <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'name',
      width: 450,
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.name.en, b.name.en),
      },
      render: (value, data) => (
        <Flex display="flex">
          <Popover placement="rightTop" content={Preview(data.image)}>
            <PreviewImage width={40} height={40} src={data.image} />
          </Popover>
          <Text
            type="subheading-regular"
            color={theme.gray800}
            ml="8px"
            mt="10px">
            {data.name.en}
          </Text>
        </Flex>
      ),
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Status <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'status',
      width: 180,
      render: (value) => getStatusTag(value),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.status, b.status),
      },
    },
    {
      title: 'Category',
      dataIndex: 'type',
      width: 200,
      render: (value) => getCategoryTag(value),
    },

    ...(roleId === UserRoleIdEnum.BUSINESS_OWNER ||
    roleId === UserRoleIdEnum.ADMIN
      ? [
          {
            title: (
              <Flex display="flex" alignItems="center">
                Manage By <Sort style={{ marginLeft: '8px' }} />
              </Flex>
            ),
            dataIndex: 'manageType',
            width: 200,
            render: (value: ManageByEnum) => value && getManageByTag(value),
            sorter: {
              compare: (a: { manageType: string }, b: { manageType: string }) =>
                tableSorter.defaultSort(a.manageType, b.manageType),
            },
          },
        ]
      : []),

    {
      title: 'Actions',
      dataIndex: 'id',
      width: 130,
      render: (_value, data) => (
        <Dropdown
          dropdownRender={() => (
            <Menu>
              <MenuItem
                key="edit"
                hidden={data.manageType === ManageByEnum.CC_MANAGED}
                onClick={() => {
                  handleEdit(data.id);
                }}>
                Edit
              </MenuItem>
              <MenuItem
                key="archive"
                onClick={() => productStatusChange(data.id, data.status)}>
                {data.status === CleaningProductsStatusEnum.ARCHIVE
                  ? 'Active'
                  : 'Archive'}
              </MenuItem>
            </Menu>
          )}>
          <Image src={MenuThreeDots} marginLeft="20px" />
        </Dropdown>
      ),
    },
  ];
  return columns;
};

const CleaningProducts: FC = () => {
  const { isTablet, isDesktop } = useWindowSize();
  const [visible, setVisible] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const { currentUser, isLoading: currentUserIsLoading } = useAuth();
  const [isEquipment, setIsEquipment] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [editingProduct, setEditingProduct] =
    useState<CleaningProductFormI | null>(null);

  const {
    data: allProductsData,
    isLoading: allProductsIsLoading,
    refetch: refetchAllProductsData,
  } = useGetAllProducts();

  const {
    mutate: createProductMutation,
    isSuccess: createProductIsSuccess,
    isLoading: createProductIsLoading,
  } = useCreateProduct();

  const {
    mutate: toggleProductStatus,
    isSuccess: toggleProductStatusIsSuccess,
    isLoading: toggleProductStatusLoading,
  } = useToggleProductStatus();

  const {
    mutate: updateProduct,
    isSuccess: updateProductIsSuccess,
    isLoading: updateProductIsLoading,
  } = useUpdateProduct();

  const resetAndRefetch = () => {
    setVisible(false);
    setEditingProduct(null);
    refetchAllProductsData();
  };

  useEffect(() => {
    if (createProductIsSuccess || updateProductIsSuccess) {
      resetAndRefetch();
    } else if (toggleProductStatusIsSuccess) {
      refetchAllProductsData();
    }
  }, [
    createProductIsSuccess,
    toggleProductStatusIsSuccess,
    updateProductIsSuccess,
  ]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleEdit = (id: string) => {
    const product = allProductsData?.find((item) => item.id === id);
    if (product) {
      setEditingProduct({
        id: product.id,
        code: product.code,
        oldImage: product.image,
        name: product.name,
        type: product.type,
      });
      setIsEquipment(product.type === CleaningProductTypeEnum.EQUIPMENT);
      setVisible(true);
    }
  };

  const handleModalCancel = () => {
    setVisible(false);
    setEditingProduct(null);
  };

  const handleModalSubmit = (formValues: CleaningProductFormI) => {
    if (editingProduct) {
      updateProduct(formValues);
    } else {
      createProductMutation(formValues);
    }
  };

  const filterProducts = (
    item: CleaningProductsI,
    filter: string,
    searchTerm: string,
  ) => {
    const searchTermLower = searchTerm.toLowerCase();
    const nameMatch =
      item.name &&
      item.name.en &&
      item.name.en.toLowerCase().includes(searchTermLower);
    const codeMatch =
      item.code && item.code.toLowerCase().includes(searchTermLower);

    switch (filter) {
      case 'Chemical':
        return (
          item.type === CleaningProductTypeEnum.CHEMICAL &&
          (nameMatch || codeMatch)
        );
      case 'Equipment':
        return (
          item.type === CleaningProductTypeEnum.EQUIPMENT &&
          (nameMatch || codeMatch)
        );
      default:
        return nameMatch || codeMatch;
    }
  };

  const filteredData = allProductsData?.filter((item) =>
    filterProducts(item, filter, searchTerm),
  );
  const productStatusChange = (
    id: string,
    status: CleaningProductsStatusEnum,
  ) => {
    if (status === CleaningProductsStatusEnum.ARCHIVE) {
      toggleProductStatus(id);
    } else {
      setShowDeleteModal(true);
      setItemId(id);
    }
  };

  return (
    <Spin
      spinning={
        allProductsIsLoading ||
        currentUserIsLoading ||
        createProductIsLoading ||
        updateProductIsLoading ||
        toggleProductStatusLoading
      }>
      <MainCard>
        <Row>
          <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
            <H type="title3Bold" mb="17px" ml="24px">
              Cleaning Products
            </H>
          </Col>
          <Col xs={24} sm={24} md={24} lg={13} xl={13} xxl={13}>
            <Card justifyContent="end" mr="24px">
              <SearchInput size="small" onChange={handleSearch} />

              <ButtonFlex display="flex">
                <CategorySelector onChange={(value) => setFilter(value)} />
                <ButtonDropdown
                  label={'Add Item'}
                  dropdownRender={() => (
                    <Menu>
                      <MenuItem
                        key="Equipment"
                        onClick={() => {
                          setIsEquipment(true);
                          setVisible(true);
                        }}>
                        Add Equipment
                      </MenuItem>
                      <MenuItem
                        key="Chemical"
                        onClick={() => {
                          setIsEquipment(false);
                          setVisible(true);
                        }}>
                        Add Chemical
                      </MenuItem>
                    </Menu>
                  )}
                  mr="16px"
                />
              </ButtonFlex>
            </Card>
          </Col>
        </Row>
        <Flex pb="20px">
          <TableComponent
            height="77vh"
            columns={getColumns(
              currentUser?.role.id ?? 0,
              productStatusChange,
              handleEdit,
            )}
            dataSource={filteredData}
            scroll={{
              x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
              y: window.innerHeight - 200,
            }}
          />
        </Flex>
        {visible && (
          <EquipmentModal
            isEquipment={isEquipment}
            visible={visible}
            onCancel={handleModalCancel}
            onSubmit={handleModalSubmit}
            loading={createProductIsLoading || updateProductIsLoading}
            initialValues={editingProduct}
          />
        )}
        {showDeleteModal && itemId && (
          <DeleteModal
            visible={showDeleteModal}
            onClickCancel={() => toggleProductStatus(itemId)}
            content={`Are you sure you want to Archive?`}
            title="Confirm Archive"
            handleCancel={() => setShowDeleteModal(false)}
            buttonLabel="Remove"
          />
        )}
      </MainCard>
    </Spin>
  );
};
export default CleaningProducts;
