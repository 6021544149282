import { FC, useEffect, useState } from 'react';
import { Button, Dropdown, Menu, MenuProps } from 'antd';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Image } from '../../components';
import { DropdownBlack } from '../../assets';
import { useGetVenues } from '../../api/venueHooks';
import { addDotsForLongText } from '../../utils/threeDotPopoverUtil';

export const MenuComp = styled(Menu)`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  padding: 0px !important;
  width: 150px;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const MenuItem = styled.div`
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 14px;
  margin-bottom: 0px !important;
`;
export const MenuItemClear = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 14px;
`;
export const DropdownComp = styled.span`
  margin-left: 5px;
  background: ${theme.gray200};
  border-radius: 14px;
  padding: 0px 8px;
`;

type DropDownBtnProps = {
  width?: number;
};

export const DropdownButton = styled(Button)<DropDownBtnProps>`
  margin: 0px 0px 0px 16px !important;
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: ${(props) => (props.width ? `${props.width}px` : '150px')};
  padding: 4px 12px 6px 16px !important;
  text-align: start !important;
  border-radius: 8px;
  border: 0.5px solid ${theme.brandDarkTeal} !important;

  @media (max-width: 760px) {
    width: 100%;
    margin-left: 0px !important;
    margin-bottom: 8px !important;
  }

  &:hover {
    color: ${theme.brandDarkTeal} !important;
  }
  :focus {
    color: ${theme.black} !important;
    border-color: ${theme.brandDarkTeal} !important;
  }
`;
interface Props {
  onChange: (value: string, venue?: string) => void;
  defaultVenueId?: string;
  width?: number;
}

const VenuesSelector: FC<Props> = ({ onChange, defaultVenueId, width }) => {
  const { data = [] } = useGetVenues();

  const [venue, setVenue] = useState<string>();
  const [venueId, setVenueId] = useState<string>();

  const items: MenuProps['items'] = data.map((item) => {
    return {
      key: item.id,
      label: (
        <a
          onClick={() => {
            setVenue(item.name);
            setVenueId(item.id);
          }}>
          {item.name}
        </a>
      ),
    };
  });

  useEffect(() => {
    if (data.length > 0) {
      setVenue(data[0].name);
      setVenueId(data[0].id);
    }

    if (defaultVenueId && data) {
      setVenueId(defaultVenueId);
      const venueName = data.find((value) => value.id === defaultVenueId);
      if (venueName) setVenue(venueName.name);
    }
  }, [data]);

  useEffect(() => {
    if (venueId) onChange(venueId, venue);
  }, [venueId, venue]);

  return (
    <Dropdown menu={{ items }}>
      <DropdownButton width={width}>
        <div>
          {venue
            ? addDotsForLongText(venue, width && width > 150 ? 15 : 12)
            : 'Venue'}
        </div>
        <div>
          <Image src={DropdownBlack} />
        </div>
      </DropdownButton>
    </Dropdown>
  );
};

export default VenuesSelector;
