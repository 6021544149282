import { useEffect } from 'react';
import {
  Navigate,
  Route,
  useLocation,
  RouterProvider,
  useNavigate,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import AddItems from './containers/add-items/AddItemsMainLayout';
import ForgotPasswordPage from './containers/auth/ForgotPasswordPage';
import LoginPage from './containers/auth/LoginPage';
import ResetPassword from './containers/auth/ResetPassword';
import CleaningProducts from './containers/cleaning-products/CleaningProducts';
import ItemList from './containers/item/ItemList';
import Label from './containers/labels/Label';
import Layout from './containers/layout/Layout';
import PageNotFound from './containers/layout/PageNotFound';
import Categories from './containers/settings/Categories/Categories';
import ChangePassword from './containers/settings/changePassword/ChangePassword';
import CodeList from './containers/settings/code/CodeList';
import ProfileSetting from './containers/settings/profile/ProfileSetting';
import Users from './containers/settings/users/Users';
import VenuesAndWorkingHours from './containers/settings/venues/VenuesAndWorkingHours';
import { useAuth } from './providers/auth-context';
import { PermissionEnum } from './types/permission.types';
import { isAllowed } from './utils/utils';
import DeleteAccountPage from './containers/auth/DeleteAccountPage';
import ConfirmDeleteAccountPage from './containers/auth/ConfirmDeleteAccountPage';
import TipList from './containers/tip/TipList';
import AddTips from './containers/add-items/AddTips';
import Reports from './containers/reports/Reports';

interface RequireAuthProps {
  component: JSX.Element;
  routeAccess: PermissionEnum;
}

const RequireAuth = ({ component, routeAccess }: RequireAuthProps) => {
  const { isLoggedIn, permission } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  if (!isLoggedIn) {
    return (
      <Navigate
        to={`/login?redirectTo=${location?.pathname}${location?.search}`}
      />
    );
  }

  if (isLoggedIn && !isAllowed(permission, routeAccess)) {
    navigate('/');
  }

  return component;
};

const IndexPage = () => {
  const { permission } = useAuth();
  const history = useNavigate();

  useEffect(() => {
    if (isAllowed(permission, PermissionEnum.SUPER_ADMIN_FEATURE)) {
      history('/items');
    } else {
      history('/login');
    }
  }, [permission]);

  return null;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<IndexPage />} />
      <Route
        path="/"
        element={
          <RequireAuth
            component={<Layout />}
            routeAccess={PermissionEnum.SUPER_ADMIN_FEATURE}
          />
        }>
        <Route path="/" element={<Navigate to={'/items'} />} />
        <Route path="items">
          <Route path="add-items" element={<AddItems />} />
          <Route path="edit-item/:venueId/:id" element={<AddItems />} />
          <Route path="edit-item/:id" element={<AddItems />} />
          <Route
            path="duplicate-item/:venueId/:duplicateId"
            element={<AddItems />}
          />
          <Route path="duplicate-item/:duplicateId" element={<AddItems />} />
          <Route path=":venueIdParam?" element={<ItemList />} />
        </Route>

        <Route path="cleaning-products" element={<CleaningProducts />} />

        <Route path="settings">
          <Route path="profile" element={<ProfileSetting />} />
          <Route path="reset-password" element={<ChangePassword />} />
          <Route path="users" element={<Users />} />
          <Route path="category" element={<Categories />} />
          <Route path="code" element={<CodeList />} />
        </Route>
      </Route>

      <Route
        path="/"
        element={
          <RequireAuth
            component={<Layout />}
            routeAccess={PermissionEnum.MANAGE_LABELS}
          />
        }>
        <Route path="labels" element={<Label />} />
      </Route>

      <Route
        path="/"
        element={
          <RequireAuth
            component={<Layout />}
            routeAccess={PermissionEnum.MANAGE_LABELS}
          />
        }>
        <Route path="tips">
          <Route path=":tipIdParam?" element={<TipList />} />
          <Route path="add-tips" element={<AddTips />} />
          <Route path="edit-tip/:id" element={<AddTips />} />
          <Route path="duplicate-tip/:duplicateId" element={<AddTips />} />
        </Route>
      </Route>

      <Route
        path="/"
        element={
          <RequireAuth
            component={<Layout />}
            routeAccess={PermissionEnum.EDIT_BUSINESS_HOURS}
          />
        }>
        <Route path="reports" element={<Reports />} />
        <Route
          path="settings/venues-business-hours"
          element={<VenuesAndWorkingHours />}
        />
      </Route>
      <Route path="login" element={<LoginPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route
        path="reset-password/:token?/:tempPassword?"
        element={<ResetPassword />}
      />
      <Route path="delete-account" element={<DeleteAccountPage />} />
      <Route
        path="delete-account/:token"
        element={<ConfirmDeleteAccountPage />}
      />
      <Route path="404/:token?" element={<PageNotFound />} />

      <Route path="*" element={<Navigate to="/404" />} />
    </>,
  ),
);

export const NavigationRoutes = () => {
  return <RouterProvider router={router} />;
};
