const MOBILE_MAX_WIDTH = 760;
const TABLET_MAX_WIDTH = 1030;
const LAPTOP_MAX_WIDTH = 1440;
const DESKTOP_MAX_WIDTH = 2000;

const LAPTOP_MAX_HEIGHT = '900px';
const DESKTOP_MAX_HEIGHT = '1080px';

const theme = {
  black: '#000000',
  white: '#FFFFFF',

  //....Primary Colors...............
  brandTeal: '#66CCB0',
  brandDarkTeal: '#254349',
  brandDark: '#232624',
  brandCream: '#F5EEE1',
  tealSeco: '#F0FAF7',
  //....Gray Colors...................
  gray100: '#FAFAFA',
  gray200: '#F3F3F3',
  gray300: '#ECECEC',
  gray400: '#DDDDDD',
  gray500: '#DADADA',
  gray600: '#939393',
  gray700: '#575757',
  //..........secondary color.................
  gray800: '#666666',

  //..........red color.................
  red50: '#FFF2F1',

  //....Tags Colors.................
  green: '#00B505',
  red: '#ED2115',
  darkRed: '#c91910',
  blue: '#0075FF',
  yellow: '#FFD600',
  lightPurple: '#F1DEFC',
  lightBlue: '#DAF6FF',
  lightCream: '#FFF2DE',
  lightGreen: '#F4F7F6',

  //.... Labels Colors..............
  selectColor: 'rgba(102, 204, 176, 0.4)',
  brandTeal20: 'rgba(102, 204, 176, 0.2)',
  brandTeal10: 'rgba(0, 204, 192, 0.1)',
  yellow10: 'rgba(255, 214, 0, 0.1)',
  red10: 'rgba(237, 33, 21, 0.1)',
  disabledInputText: 'rgba(0, 0, 0, 0.25)',

  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
  LAPTOP_MAX_WIDTH,
  DESKTOP_MAX_WIDTH,

  LAPTOP_MAX_HEIGHT,
  DESKTOP_MAX_HEIGHT,
};

export default theme;
